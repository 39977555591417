import { useEffect, useState } from "react"

import { useFetchProviderHomes } from "src/data/homes/queries/responseServiceQueries"
import { TMaybeHome } from "src/data/homes/types/homeTypes"
import { IProviderHomeResponse } from "src/data/homes/types/responseServiceTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHomes } from "src/data/organizations/queries/homeQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { ComboboxStateful } from "src/ui/Combobox/ComboboxStateful"

export function HomesDropdown({
  value,
  onSelect,

  getOptionDisabled,
  getOptionDescription,
  required,
  initialHomeId,
  fetchProviders,
}: {
  onSelect: (h: TMaybeHome) => void
  value: TMaybeHome
  getOptionDisabled?: (
    option: TMaybeHome,
    providerList?: IProviderHomeResponse
  ) => boolean
  getOptionDescription?: (
    option: TMaybeHome,
    providerList?: IProviderHomeResponse
  ) => string
  required?: boolean
  initialHomeId?: string
  fetchProviders?: boolean
}) {
  const { t, langKeys } = useTranslate()
  const [search, setSearch] = useState("")
  const { orgId } = useOrganization()
  const fetchHomes = useFetchHomes({
    orgId,
    filters: { name: search },
  })

  const homeIds = fetchHomes.data?.homes.map((h) => h.home_id) || []

  const fetchProviderHomes = useFetchProviderHomes({
    orgId,
    homeIds: homeIds,
    options: {
      enabled: !!fetchProviders && homeIds?.length > 0,
    },
  })

  // If an initial id is provided, set that as our initial value as soon as the
  // data has been loaded.
  useEffect(() => {
    if (initialHomeId && !value?.name && fetchHomes.data?.homes) {
      const initialHome = fetchHomes.data?.homes.find(
        (h) => h.home_id === initialHomeId
      )
      onSelect(initialHome ?? null)
    }
  }, [fetchHomes.data?.homes, initialHomeId, onSelect, value])

  return (
    <ComboboxStateful
      options={
        fetchHomes.data?.homes.map((home) => ({
          value: home.home_id,
          label: home.name || home.home_id,
          disabled:
            !!getOptionDisabled &&
            getOptionDisabled(home, fetchProviderHomes.data),
          description:
            getOptionDescription &&
            getOptionDescription(home, fetchProviderHomes.data),
        })) ?? []
      }
      selectedValue={value?.home_id ?? ""}
      onSearch={setSearch}
      onChange={(value) => {
        const selectedHome = fetchHomes.data?.homes.find(
          (home) => home.home_id === value
        )

        if (selectedHome) {
          onSelect(selectedHome)
        }
      }}
      label={t(langKeys.home)}
      required={required}
    />
  )
}
